$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Button {
	position: relative;
	display: inline-block;
	font-family: 'AvenirBold';
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	color: $text-dark;
	background-color: white;
	padding: 0.1em 0.5em;
	border-radius: 0.5em;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	&--isDisabled {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&--isLoading {
		background-image: url('../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: center right;
		background-repeat: no-repeat;
	}
	&--cookies {
		position: absolute;
		right: 2em;
		top: 2em;
		width: 7.5em;
		height: 2em;
		color: white;
		font-weight: normal;
		background-color: $text-dark;
		border-radius: 0.56em;
		@include flex('center', 'center');
	}
}