$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Login {
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-image: url('../../assets/images/bg-login.svg');
	background-repeat: no-repeat;
	background-size: 57.104375em auto;
	background-position: center 5em;
	@include flex('flex-start', 'center');
	flex-direction: column;
	.Login-logo-wrap {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		bottom: 0;
		z-index: 1;
		.Login-cgl-logo {
			width: 8em;
			height: 10em;
			background-image: url('../../assets/images/logo-cgl-dark.svg');
			background-repeat: no-repeat;
			background-size: 7em auto;
			background-position: left center;
			margin-right: 1em;
			
		}
		
		.Login-LF-logo {
			width: 8em;
			height: 3em;
			background-image: url('../../assets/images/LandbrugFodevare.svg');
			background-repeat: no-repeat;
			background-size: auto auto;
			background-position: center center;
		}
	}
	
	.Login-wrap {
		position: relative;
		width: 30em;
		text-align: center;
		padding-top: 1em;
		z-index: 2;
		.Login-title {
			margin: 0.5em 0;
			margin-bottom: 7em;
			text-transform: uppercase;
			font-family: 'AvenirBold';
			letter-spacing: 0.07em;
		}
	}

	.PigImageLogin {
		width: 19.375em;
		margin-bottom: 1em;
		height: auto;
	}

	.Login-box {
		position: relative;
		display: inline-block;
		text-align: left;
		width: 15.625em;
		max-height: 100%;
		.Login-tabContainer {
			width: 100%;
			height: 2.25em;
			background-color: rgba($blue-dark, 0.3);
			border-radius: 0.75em;
			margin-bottom: 1.5em;
			@include flex('center', 'center');
			.Login-tabButton {
				text-transform: uppercase;
				font-family: 'AvenirBold';
				height: 100%;
				width: 50%;
				border-radius: 0.75em;
				cursor: pointer;
				color: white;
				@include flex('center', 'center');
				span {
					font-size: 1em;
					font-weight: normal;
				}
				&.active {
					background-color: $blue-dark;
					// background-image: linear-gradient(darken($blue-dark, 10%), $blue-dark);
				}
			}
		}
		.Login-formContainer {
			height: calc(100% - 3.56em);
			.LoginTeacher,
			.LoginStudent,
			.LoginChooseStudent {
				width: 100%;
				height: 100%;
				text-align: left;
				.Login-info {
					color: $text-dark;
					font-size: 0.95em;
					font-weight: normal;
					margin-top: 0;
					line-height: 1.5;
					margin-bottom: 1.5em;
				}
				.Login-form {
					position: relative;
					&.feedback {
						.Login-infoLink {
							color: white;
							cursor: pointer;
							&:hover {text-decoration: underline;}
						}
					}
					input {
						display: block;
						font-size: 1em;
						width: 100%;
						height: 2.25em;
						border: none;
						border-radius: 0.75em;
						padding: 0.56em 1em;
						margin: auto;
						margin-bottom: 0.5em;
						background-color: white;
						color: $blue-dark;
						font-weight: normal;
						&::placeholder {color: $blue-dark;}
					}
					.Button {
						font-size: 1em;
						width: 100%;
						height: 2.25em;
						border-radius: 0.75em;
						margin-top: 1em;
						margin-bottom: 0.5em;
						color: white;
						font-weight: normal;
						background-color: $blue-dark;
						text-transform: none;
						// background-image: linear-gradient(darken($blue-dark, 10%), $blue-dark);
						@include flex('center', 'center');
						&--isLoading {
							// background-image: url('../../assets/images/icon-loading.svg'), linear-gradient(darken($blue-dark, 10%), $blue-dark);
							background-image: url('../../assets/images/icon-loading.svg');
							background-size: auto 80%;
							background-position: center right;
							background-repeat: no-repeat;
						}
					}
				}	
				.Login-students {
					width: 100%;
					height: 100%;
					overflow: auto;
					white-space:nowrap;
					.Login-studentSelectBtn,
					.Login-newStudentBtn {
						width: 100%;
						height: 2.25em;
						border-radius: 0.56em;
						margin-bottom: 1em;
						color: white;
						font-weight: normal;
						background-color: $blue-dark;
						background-image: linear-gradient(darken($blue-dark, 10%), $blue-dark);
						@include flex('center', 'center');
						cursor: pointer;
						&.loading {
							// background-image: url('../../assets/images/icon-loading.svg'), linear-gradient(darken($blue-dark, 10%), $blue-dark);
							background-image: url('../../assets/images/icon-loading.svg');
							background-size: auto 80%;
							background-position: center right;
							background-repeat: no-repeat;
						}
					}
				}
				.Login-resetPasswordBtn,
				.Login-createUserBtn {
					width: 100%;
					line-height: 1.5em;
					font-size: 0.8em;
					font-weight: normal;
					text-align: center;
					color: $text-light;
					cursor: pointer;
				}
			}
		}
	}
	
}

.Login-buttons {
	@include flex('space-between', 'center');
	.Button.Button--login { 

		margin: 0 0.5em;
		&:nth-child(1) {margin-left: 0;}
		&:nth-last-child(1) {margin-right: 0;}
	}
}


.Login-errorMessage {
	height: 1.25em;
	line-height: 1em;
	margin-top: -1em;
	margin-bottom: 1.75em;
	padding-top: 1em;
	font-size: 0.75em;
	color: red;
}

// @media only screen and (max-aspect-ratio: 1/1) {

// 	.Login {
// 		font-size: 12px;
// 		.Login-panel {
// 			width: 100%;
// 			padding: 0 4em;
// 			padding-top: 4em;
// 		}
// 		.Login-box {
// 			width: 100%;
// 			height: auto;
// 		}
// 	}
// }