// FLEX
@mixin flex($justify: '', $align: '', $direction: '') {
	display: -webkit-box; // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box; // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox; // TWEENER - IE 10
	display: -webkit-flex; // NEW - Chrome
	display: flex; // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}

@mixin inline-flex($justify: '', $align: '', $direction: '') {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	@include flex-properties($justify, $align, $direction);
}

@mixin flex-properties($justify, $align, $direction) {
	@if $justify != '' {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align != '' {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};
	}
	@if $direction != '' {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}

@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap; /* Safari 6.1+ */
	flex-wrap: $wrap;
}

@mixin align-content($align) {
	-webkit-align-content: $align;
	align-content: $align;
}

@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

// TRANSFORMS
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

@mixin scale3d($scaleX, $scaleY, $scaleZ) {
	-ms-transform: scale3d($scaleX, $scaleY, $scaleZ); /* IE 9 */
	-webkit-transform: scale3d($scaleX, $scaleY, $scaleZ); /* Safari */
	transform: scale3d($scaleX, $scaleY, $scaleZ);
}

@mixin translateX($x) {
	-webkit-transform: translateX($x);
	-ms-transform: translateX($x);
	-o-transform: translateX($x);
	transform: translateX($x);
}

// TRANSITION
@mixin transition($target,$seconds,$type) {	
	-moz-transition: #{$target} $seconds #{$type};		/* Firefox */
	-webkit-transition: #{$target} $seconds #{$type};	/* WebKit */
	-o-transition: #{$target} $seconds #{$type};			/* Opera */
	transition: #{$target} $seconds #{$type};					/* Standard */
}

@mixin doubleTransition($target,$seconds,$type,$target2,$seconds2,$type2) {	
	-moz-transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};		/* Firefox */
	-webkit-transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};	/* WebKit */
	-o-transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};			/* Opera */
	transition: #{$target} $seconds #{$type}, #{$target2} $seconds2 #{$type2};					/* Standard */
}

// NO-SELECT
@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently*/
}

// OPACITY
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity='
		$procentOpacity ')'; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}

// ANIMATIONS
@mixin fadeInOut($duration) {
	visibility: visible;
	animation: fadeInOut #{$duration}s infinite ease-out 0s;
	-webkit-animation: fadeInOut #{$duration}s infinite ease-out 0s;
	-moz-animation: fadeInOut #{$duration}s infinite ease-out 0s;
	-o-animation: fadeInOut #{$duration}s infinite ease-out 0s;
	animation-direction: alternate;
}

@mixin flicker($duration) {
	visibility: visible;
	animation: flicker #{$duration}s infinite ease-out 0s;
	-webkit-animation: flicker #{$duration}s infinite ease-out 0s;
	-moz-animation: flicker #{$duration}s infinite ease-out 0s;
	-o-animation: flicker #{$duration}s infinite ease-out 0s;
}

@mixin changeIconBgColor($duration) {
	visibility: visible;
	animation: changeIconBgColor #{$duration}s infinite ease-out 0s;
	-webkit-animation: changeIconBgColor #{$duration}s infinite ease-out 0s;
	-moz-animation: changeIconBgColor #{$duration}s infinite ease-out 0s;
	-o-animation: changeIconBgColor #{$duration}s infinite ease-out 0s;
	animation-direction: alternate;
}

@mixin changeBgColor($duration) {
	visibility: visible;
	animation: changeBgColor #{$duration}s infinite ease-out 0s;
	-webkit-animation: changeBgColor #{$duration}s infinite ease-out 0s;
	-moz-animation: changeBgColor #{$duration}s infinite ease-out 0s;
	-o-animation: changeBgColor #{$duration}s infinite ease-out 0s;
	animation-direction: alternate;
}

@mixin changeBgColor2($duration) {
	visibility: visible;
	animation: changeBgColor2 #{$duration}s infinite ease-out 0s;
	-webkit-animation: changeBgColor2 #{$duration}s infinite ease-out 0s;
	-moz-animation: changeBgColor2 #{$duration}s infinite ease-out 0s;
	-o-animation: changeBgColor2 #{$duration}s infinite ease-out 0s;
	animation-direction: alternate;
}

@mixin pulse($duration) {
	-moz-animation: pulse #{$duration}s infinite ease-out; /* Fx 5+ */
	-o-animation: pulse #{$duration}s infinite ease-out; /* Opera 12+ */
	-webkit-animation: pulse #{$duration}s infinite ease-out;
	animation: pulse #{$duration}s infinite ease-out;
}

@mixin pigZoomOut($duration, $delay: 0) {
	transform-origin: calc(70vw - 38%) -8em;
	-moz-animation: pigZoomOut #{$duration}s ease-out #{$delay}s 1 forwards; /* Fx 5+ */
	-o-animation: pigZoomOut #{$duration}s ease-out #{$delay}s 1 forwards; /* Opera 12+ */
	-webkit-animation: pigZoomOut #{$duration}s ease-out #{$delay}s 1 forwards;
	animation: pigZoomOut #{$duration}s ease-out #{$delay}s 1 forwards;
}

@mixin pigCutZoomIn($duration, $delay: 0) {
	transform-origin: 50% -1em;
	-moz-animation: pigCutZoomIn #{$duration}s ease-out #{$delay}s 1 forwards; /* Fx 5+ */
	-o-animation: pigCutZoomIn #{$duration}s ease-out #{$delay}s 1 forwards; /* Opera 12+ */
	-webkit-animation: pigCutZoomIn #{$duration}s ease-out #{$delay}s 1 forwards;
	animation: pigCutZoomIn #{$duration}s ease-out #{$delay}s 1 forwards;
}

// BOX SHADOW
@mixin box-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

/* Box shadow for svgs, pngs, etc. */
@mixin box-shadow-picture($hoffset, $voffset, $blur, $color, $second_hoffset:0, $second_voffset:0, $second_blur:0, $second_color:transparent) {
	-webkit-filter: drop-shadow($hoffset $voffset $blur $color) drop-shadow($second_hoffset $second_voffset $second_blur $second_color);
	filter: drop-shadow($hoffset $voffset $blur $color) drop-shadow($second_hoffset $second_voffset $second_blur $second_color);
}

// SCROLLBARS
/* HIde scrollbar */
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	&::-webkit-scrollbar {display: none;}
}

/* Scrollbar */
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
@mixin fancy-scrollbar($width,$height,$backgroundColor,$borderRadius,$thumbWidth, $thumbHeight, $thumbColor, $thumbBorder: none) { 
	&::-webkit-scrollbar {
		width: $width;
		height: $height;
	}
	&::-webkit-scrollbar-track {
		background-color: $backgroundColor;
		border-radius: $borderRadius;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $borderRadius;
		background: $thumbColor;
		width: $thumbWidth;
		height: $thumbHeight;
		border: $thumbBorder;
	}
	&::-webkit-scrollbar-thumb:window-inactive {
		background-color: transparent;
		border: 1px solid $thumbColor;
	}
}

@mixin custom-scrollbar($width, $backgroundColor, $thumbColor) {
	/* Chrome, Safari and Edge */
	&::-webkit-scrollbar {
		width: $width; /* width of the veritcal scrollbar */
		height: $width; /* width of the horizontal scrollbar */
	}
	&::-webkit-scrollbar-track {
		background-color: $backgroundColor;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		border-radius: $width;
		border: 1px solid $backgroundColor;
	}
	&::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
	/* Firefox */
	scrollbar-width: thin;
	scrollbar-color: $thumbColor $backgroundColor;
}

// APPEARANCE
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance:    #{$value};
	appearance:         #{$value};
}

// ANIMATIONS
@mixin fadeIn($duration) {
	visibility: visible;
	animation: fadeInOut #{$duration}s forwards ease-out 0s;
	-webkit-animation: fadeInOut #{$duration}s forwards ease-out 0s;
	-moz-animation: fadeInOut #{$duration}s forwards ease-out 0s;
	-o-animation: fadeInOut #{$duration}s forwards ease-out 0s;
	animation-direction: alternate;
}