$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CookieConsent {
	position: fixed;
	width: 100%;
	bottom: -9em;
	color: $text-dark;
	background: $blue-light;
	height: auto;
	-webkit-animation: slideIn 0.5s linear 0.5s 1 forwards;
	animation: slideIn 0.5s linear 0.5s 1 forwards;
	z-index: 100;
}

.CookieConsent-text {
	padding: 2em 14em 2em 5em;
	text-align: left;
	span {
		font-size: 1em;
	}
	a {
		font-size: 1em;
		color: $text-dark;
	}
}


@-webkit-keyframes slideIn {
	100% {bottom: 0}
}
@keyframes slideIn {
	100% {bottom: 0}
}


@media only screen and (max-aspect-ratio: 1/1) {
	.CookieConsent-text {
		padding: 0.5em 10em 0.5em 1em;
		line-height: 1.05;
	}
}