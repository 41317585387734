@-webkit-keyframes rotate {
0% {transform: rotate(0deg);}
50% {transform: rotate(180deg);}
100% {transform: rotate(360deg);}
}
@keyframes rotate {
0% {transform: rotate(0deg);}
50% {transform: rotate(180deg);}
100% {transform: rotate(360deg);}
}

@-webkit-keyframes bounce {
	from {
		transform: translateX(-30%) scale(1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
		transform: translateX(-30%) scale(1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes bounce {
	from {
		transform: translateX(-30%) scale(1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
		transform: translateX(-30%) scale(1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes wobble {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	15% {
		-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	30% {
		-webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
		transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	45% {
		-webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
		transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
	}

	60% {
		-webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
		transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
	}

	75% {
		-webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
		transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes wobble {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	15% {
		-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	30% {
		-webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
		transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	45% {
		-webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
		transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
	}

	60% {
		-webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
		transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
	}

	75% {
		-webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
		transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2021-9-21 16:17:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
	0% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
	30% {
	  -webkit-transform: scale3d(1.25, 0.75, 1);
			  transform: scale3d(1.25, 0.75, 1);
	}
	40% {
	  -webkit-transform: scale3d(0.75, 1.25, 1);
			  transform: scale3d(0.75, 1.25, 1);
	}
	50% {
	  -webkit-transform: scale3d(1.15, 0.85, 1);
			  transform: scale3d(1.15, 0.85, 1);
	}
	65% {
	  -webkit-transform: scale3d(0.95, 1.05, 1);
			  transform: scale3d(0.95, 1.05, 1);
	}
	75% {
	  -webkit-transform: scale3d(1.05, 0.95, 1);
			  transform: scale3d(1.05, 0.95, 1);
	}
	100% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  }
  @keyframes jello-horizontal {
	0% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
	30% {
	  -webkit-transform: scale3d(1.25, 0.75, 1);
			  transform: scale3d(1.25, 0.75, 1);
	}
	40% {
	  -webkit-transform: scale3d(0.75, 1.25, 1);
			  transform: scale3d(0.75, 1.25, 1);
	}
	50% {
	  -webkit-transform: scale3d(1.15, 0.85, 1);
			  transform: scale3d(1.15, 0.85, 1);
	}
	65% {
	  -webkit-transform: scale3d(0.95, 1.05, 1);
			  transform: scale3d(0.95, 1.05, 1);
	}
	75% {
	  -webkit-transform: scale3d(1.05, 0.95, 1);
			  transform: scale3d(1.05, 0.95, 1);
	}
	100% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  }
  

@-webkit-keyframes pulse {
	50% {@include scale(1.05)}
}
@keyframes pulse {
	50% {@include scale(1.05)}
}

// @keyframes flicker {
// 	50% {@include opacity(0);}
// }
// @-webkit-keyframes flicker {
// 	50% {@include opacity(0);}
// }

@keyframes flicker {
	0%, 40%       {@include opacity(0);}
	70%, 100%     {@include opacity(1);}
}
@-webkit-keyframes flicker {
	0%, 40%       {@include opacity(0);}
	70%, 100%     {@include opacity(1);}
}

@keyframes fadeInOut {
	0%, 30%       {@include opacity(0);}
	70%, 100%     {@include opacity(1);}
}
@-webkit-keyframes fadeInOut {
	0%, 30%       {@include opacity(0);}
	70%, 100%     {@include opacity(1);}
}

@keyframes changeIconBgColor {
	0%, 30%       {fill: $blue-sky;}
	70%, 100%     {fill: $blue-dark;}
}
@-webkit-keyframes changeIconBgColor {
	0%, 30%       {fill: $blue-sky;}
	70%, 100%     {fill: $blue-dark;}
}

@keyframes changeBgColor {
	0%, 30%       {background-color: $blue-sky;}
	70%, 100%     {background-color: $blue-dark;}
}
@-webkit-keyframes changeBgColor {
	0%, 30%       {background-color: $blue-sky;}
	70%, 100%     {background-color: $blue-dark;}
}

@keyframes changeBgColor2 {
	0%, 30%       {background-color: $blue-dark; color: white;}
	70%, 100%     {background-color: white;}
}
@-webkit-keyframes changeBgColor2 {
	0%, 30%       {background-color: $blue-dark; color: white;}
	70%, 100%     {background-color: white;}
}

@-webkit-keyframes pigZoomOut {
	0% {
		-ms-transform: scale(1); /* IE 9 */
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-ms-transform: scale(0.4); /* IE 9 */
		-webkit-transform: scale(0.4);
		transform: scale(0.4);
	}
}

@-moz-keyframes pigZoomOut {
	0% {
		-ms-transform: scale(1); /* IE 9 */
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-ms-transform: scale(0.4); /* IE 9 */
		-webkit-transform: scale(0.4);
		transform: scale(0.4);
	}
}

@-o-keyframes pigZoomOut {
	0% {
		-ms-transform: scale(1); /* IE 9 */
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-ms-transform: scale(0.4); /* IE 9 */
		-webkit-transform: scale(0.4);
		transform: scale(0.4);
	}
}

@keyframes pigZoomOut {
	0% {
		-ms-transform: scale(1); /* IE 9 */
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-ms-transform: scale(0.4); /* IE 9 */
		-webkit-transform: scale(0.4);
		transform: scale(0.4);
	}
}

@-webkit-keyframes pigCutZoomIn {
	0% {@include scale(0);}
	100% {@include scale(1);}
}

@-moz-keyframes pigCutZoomIn {
	0% {@include scale(0);}
	100% {@include scale(1);}
}

@-o-keyframes pigCutZoomIn {
	0% {@include scale(0);}
	100% {@include scale(1);}
}

@keyframes pigCutZoomIn {
	0% {@include scale(0);}
	100% {@include scale(1);}
}