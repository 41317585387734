$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Page {
	text-align: left;
	position: relative;
	width: 100%;
	height: 100%;
	.LogoutBtn {
		position: absolute;
		top: 0.5em;
		left: 1em;
		width: 5em;
		height: 2em;
		font-size: 1em;
		cursor: pointer;
		z-index: 100;
	}
	.ResetBtn {
		position: absolute;
		top: 0.5em;
		left: 5em;
		font-size: 1em;
		cursor: pointer;
	}

	&--teacher {
		padding: 2em 1em 0 1em;
		display: block;
		@include flex('center', 'center');
	}
}