$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Theme {
	height: auto;
}

.Theme-name {
	margin-top: 1em;
	font-size: 1.25em;
	text-transform: uppercase;
	font-family: 'AvenirBold';
}

.Theme-intro {
	font-size: 1.05em;
	padding-right: 5%;
	.Theme-introTitle {
		font-size: 1.125em;
		font-family: 'AvenirBold';
	}
	p {
		margin-top: 0.5em;
	}
}

.Theme-pigImage {
	width: 15.625em;
	height: 9.04625em;
	background-image: url('../../assets/images/pig-bronze-medal.svg');
	background-size: contain;
	&--silver {
		background-image: url('../../assets/images/pig-silver-medal.svg');
	}
	&--gold {
		background-image: url('../../assets/images/pig-gold-medal.svg');
	}
}

.Theme-btnWrap {
	display: inline-flex;
}

.Theme-backBtn,
.Theme-hintBtn,
.Theme-startBtn {
	background-color: $blue-sky;
	font-family: 'AvenirBold';
	color: $white;
	border-radius: 0.65em;
	padding-top: 0.4em;
	width: 7.2625em;
	height: 2.25em;
	text-align: center;
	margin-top: 2em;
	margin-right: 1em;
	font-size: 1em;
	border: 0.15em solid transparent;
	cursor: pointer;
	display: inline-block;
}

.Theme-header {
	top: 0;
	left: 0;
	width: 100%;
	height: 4.5em;
}

.Theme-body {
	padding: 2em;
}