/* BODY TEXT */
@font-face {
	font-family: Avenir;
	src: url('../assets/fonts/AvenirMedium.ttf');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: AvenirBold;
	src: url('../assets/fonts/AvenirLTStd-Black.otf');
	font-style: normal;
	font-family: 'AvenirBold';
}

@font-face {
	font-family: Avenir;
	src: url('../assets/fonts/AvenirLTStd-Book.otf');
	font-style: normal;
	font-weight: 300;
}