$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Sort {
	height: 100%;
	&--paused {.Sort-item {cursor: not-allowed;}}
}

.Sort-header {
	position: relative;
	padding-top: 1em;
	text-align: left;
	z-index: 1;
}

.Sort-question {
	position: relative;
	font-size: 1.25em;
	line-height: 1.2;
	text-align: left;
	color: $white;
	p {display: inline; margin: 0;}
}

.Sort-boxes {
	width: 100%;
	margin: 1em 0;
	@include flex('space-between', 'center');
}

.Sort-boxContainer {
	display: inline-block;
	font-family: 'AvenirBold';
	width: 13em;
	padding: 0.75em 0.25em;
	color: $white;
	border-radius: 0.75em;
	background-color: rgba($blue-sky, 0.5);
	text-align: center;
	margin-right: 1em;
	&:last-of-type {
		margin-right: 0;
	}
	// &--red {background-color: red;}
	// &--green {background-color: green;}
	// @include box-shadow(0, 2px, 2px, 0, rgba(#000000, 0.56));
	
}
.Sort-boxText {
	height: 1em;
	padding: 0 0.5em;
	font-size: 1.25em;
	font-family: 'AvenirBold';
	cursor: pointer;
	p {font-family: 'AvenirBold'; display: inline; margin: 0; cursor: pointer;}
	@include flex('center', 'center');
	@include no-select();
}

.Sort-body {
	position: relative;
	padding-top: 2em;
	z-index: 1;
}

.Sort-item {
	@include flex('center', 'center');
	position: relative;
	width: 100%;
	margin-bottom: 1em;
	line-height: 1.2;
	text-align: center;
	color: $white;
	border-radius: 0.75em;
	background-color: rgba($blue-sky, 0.5);
	// background-image: url('../../../assets/images/box-answer-4.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 0.5em 1em;
	cursor: pointer;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	font-size: 1.24em;
	p {display: inline; margin: 0;}
	&.selected {
		color: $white;
		background-color: rgba($blue-sky, 1);
		// background-image: url('../../../assets/images/box-answer-4-selected.svg');
	}
	img {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		max-width: 5.625em;
		max-height: 80%;
	}
	&.Sort-item--img {
		height: 3em;
		p {
			position: absolute;
			left: 61%;
			top: 1em;
			@include opacity(0);
			visibility: hidden;
			@include doubleTransition('opacity', 0.2s, 'ease-out', 'visibility', 0.2s, 'ease-out');
		}
		&.selected,
		&.correct,
		&.wrong,
		&:hover {
			p {
				@include opacity(1);
				visibility: visible;
			}
		}
	}

	&.placed {display: none;}
	&.correct {
		color: $white;
		background-image: linear-gradient(#78BA59, #128F33);
		// background-image: url('../../../assets/images/box-answer-4-correct.svg');
		-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
	&.wrong {
		color: $white;
		background-image: linear-gradient(#D1091F, #82202F);
		// background-image: url('../../../assets/images/box-answer-4-wrong.svg');
		-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
}