$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Challenge {
	position: relative;
	.Challenge-themeName {
		margin-top: 1em;
		font-family: 'AvenirBold';
		font-size: 1.25em;
		text-transform: uppercase;
	}
	
	.Challenge-body {
		position: relative;
		width: 100%;
		top: 0;
		left: 0;
		font-size: 0.8em;
		&.hide {
			opacity: 0;
		}
	}
	
	.Challenge-btn {
		background-color: $blue-sky;
		color: white;
		padding: 0.5em 0;
		border-radius: 0.5em;
		width: 7em;
		text-align: center;
		margin-top: 1.5em;
		z-index: 1;
		position: relative;
		cursor: pointer;
	}

	.ThemeProgress {
		font-size: 1em;
	}
}
