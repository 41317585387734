$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Order {
	height: 100%;
	&--paused {.Order-item {cursor: not-allowed;}}
}

.Order-header {
	// position: absolute;
	// top: 0em;
	// left: 0em;
	// right: 0em;
	padding: 0.5em 0;
	text-align: center;
	z-index: 1;
	// background-image: url('../../../assets/images/background.jpg');
	background-size: cover;
}

.Order-question {
	position: relative;
	font-size: 1.25em;
	line-height: 1.2;
	text-align: left;
	color: $white;
	p {display: inline; margin: 0;}
}

.Order-boxes {
	position: relative;
	margin-top: 1.5em;
	padding-bottom: 0.5em;
	text-align: left;
	@include flex('flex-start', 'flex-start');

	// &::before {
	// 	content: '';
	// 	width: calc(100% - 2em);
	// 	height: 0.25em;
	// 	background-color: #EFBE57;
	// 	position: absolute;
	// 	top: 1.35em;
	// }
}

.Order-boxContainer {
	z-index: 1;
	position: relative;
	display: inline-block;
	width: 3em;
	height: 3em;
	margin: 0 0.4em;
	background-color: rgba($blue-sky, 0.5);
	color: $white;
	border-radius: 0.5em;
	&--correct {
		background-image: linear-gradient(#78BA59, #128F33);
		.Order-boxText {
			color: white;
		}
	}
	&:nth-child(1) {margin-left: 0;}
	&:nth-last-child(1) {
		margin-right: 0;
		&::after {display: none;}
	}
}
.Order-boxText {
	@include flex('center', 'center');
	height: 100%;
	font-size: 2em;
	color: $white;
	font-family: 'AvenirBold';
	@include no-select();
	cursor: pointer;
	border-radius: 0.5em;
	padding-top: 0.2em;
}

.Order-body {
	position: relative;
	// padding-top: calc(1 * 16 * ((100vw / 360)));
	padding: 0;
	z-index: 0;
	text-align: left;
}

.Order-itemWrap {
	position: relative;
	display: block;
	height: 3em;
	margin-top: 0.5em;
}

.Order-item {
	font-family: 'AvenirBold';
	position: relative;
	@include flex('center', 'center');
	width: 100%;
	height: 100%;
	line-height: 1.1;
	text-align: center;
	color: $white;
	padding: 0.25em;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	border-radius: 0.75em;
	background-color: rgba($blue-sky, 0.5);
	p {display: inline; margin: 0;}
	font-size: 1.25em;
	@include transition(padding, 0.1s, linear);
	cursor: pointer;
	&--selected {background-color: $blue-sky;}
	&--placed {
		color: $white;
		background-image: linear-gradient(#78BA59, #128F33);
	}
	&--correct {
		color: $white;
		background-image: linear-gradient(#78BA59, #128F33);
		-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
	&--wrong {
		color: $white;
		background-image: linear-gradient(#D1091F, #82202F);
		-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
}

.Order-itemNumber {
	font-size: 2em;
	position: absolute;
	top: 0.35em;
	left: 0;
	width: 1.5em;
	height: 1em;
	z-index: 1;
	color: $white;
	font-family: 'AvenirBold';
	line-height: 1;
	text-align: center;
	&::after {
		content: '|';
		position: absolute;
		top: -0.1em;
		right: 0.1em;
		width: 1px;
		height: 1em;
	}
}