$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'animations';
html {
	position: relative;
	font-size: 1.172vw; // 18px at screen width ~1536px
	height: 100%;
}

body {
	position: relative;
	font-family: 'Avenir', sans-serif;
	font-size: 1em;
	font-weight: normal;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	color: $text-dark;
	background-color: $game-bg;
	touch-action: manipulation; // disables double-tap gestur -> no click delay by the browser
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	-webkit-overflow-scrolling: touch;
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
	font-family: 'Avenir', sans-serif;
}

button {
	background-image: none;
	-webkit-tap-highlight-color: transparent;
	@include no-select();
	&:focus {
		outline: 0;
	}
	&::-moz-focus-inner {
		border: 0;
	}
}

ul, ol {
	padding-inline-start: 1.5em;
	 margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
	font-size: inherit;
	@include appearance(none);
}
