$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	height: 100%;
	overflow: hidden;
	width: 100%;
	max-width: 50em;
	background-image: url('../../assets/images/bg-teacher.svg');
	background-size: 37.5em auto;
	background-position: -1em 18em;
	background-repeat: no-repeat;
	@include flex('space-around', 'flex-start', 'row');

	&--block {
		display: block;
		text-align: center;
		background-position: center 18em;
		.Settings-genericInfo .Settings-pigImage {
			margin: 0 auto!important;
		}
		.Settings-gameTitle {
			text-align: center!important;
			margin-right: 0 !important;
		}
	}

	.Settings-genericInfo {
		margin-right: 1em;
		.Settings-gameTitle {
			font-family: 'AvenirBold';
			position: relative;
			font-size: 1.5em;
			margin-bottom: 3em;
			text-align: right;
			margin-right: -4.1em;
			text-transform: uppercase;
		}

		.Settings-text {
			font-size: 1.2em;
			min-height: 3em;
			p {
				margin: 0;
			}
		}
	
		.Settings-pigImage {
			width: 19.375em;
			max-width: 100%;
			height: auto;
			display: block;
			position: relative;
			padding-top: 4em;
			padding-bottom: 8em;
			margin-left: 6em;
		}
	}

	.Settings-settings {
		width: 22em;
		max-width: 100%;
		font-size: 0.85em;
		margin-top: 15em;
		margin-right: 5em;
		position: relative;
		h3 {
			font-size: 1.3em;
			font-weight: normal;
			font-family: 'AvenirBold';
			text-transform: uppercase;
			margin-bottom: 0.5em;
			margin-top: 0.5em;
			padding-left: 0.5em;
		}
		&.locked {
			.Settings-themes .Settings-theme .Settings-themeName {
				cursor: initial;
				&::after,
				&::before {
					display: none;
				}
			}
			// .Settings-difficultyWrap div {display: inline-block;}
			// .Settings-difficultyBtn.selected {
			// 	background-color: transparent;
			// 	cursor: initial;
			// 	width: auto;
			// 	font-size: 1em;
			// 	color: $text-dark;
			// }
			// .Settings-difficultyBtn:not(.selected) {
			// 	display: none;
			// }
		}
		// .Settings-difficultyWrap {
		// 	margin-bottom: 0.7em;
		// 	height: 2.2em;
		// }
		// .Settings-difficultyBtn {
		// 	margin-top: 0.5em;
		// 	display: inline-block;
		// 	background-color: lightgrey;
		// 	width: 7em;
		// 	font-size: 0.8em;
		// 	padding: 0.3em;
		// 	text-align: center;
		// 	cursor: pointer;
		// 	opacity: 0.5;
		// 	@include no-select();
		// 	&:first-of-type {
		// 		border-bottom-left-radius: 0.5em;
		// 		border-top-left-radius: 0.5em;
		// 	}
		// 	&:last-of-type {
		// 		border-bottom-right-radius: 0.5em;
		// 		border-top-right-radius: 0.5em;
		// 	}
		// 	&.selected {
		// 		background-color: $blue-dark;
		// 		color: white;
		// 		opacity: 1;
		// 	}
		// }
		.Settings-themes {
			margin-top: 0em;
			padding: 0.75em;
			border-radius: 1em;
			background-color: white;
			height: auto;
			width: 25.5em;
			@include box-shadow(0, 0.1876em, 0.25em, 0, rgba($blue-dark, 0.16));
			.Settings-theme {
				width: 100%;
				margin-bottom: 1em;
				font-size: 0.8em;
				border-radius: 1em;
				background-color: rgba($blue-sky, 0.35);
				.Settings-themeName {
					font-size: 1.3em;
					margin-bottom: 0.3em;
					position: relative;
					padding: 0.5em 0.75em;
					cursor: pointer;
					// &::before {
					// 	content: "";
					// 	position: absolute;
					// 	right: 1.35em;
					// 	top: 0.6em;
					// 	width: 0.4em;
					// 	height: 0.6em;
					// 	border-left: 0.17em solid;
					// 	border-bottom: 0.17em solid;
					// 	transform: rotate(45deg) scaleX(-1) scale(0);
					// 	transition: all 0.2s ease;
					// }
					// &::after {
					// 	content: "";
					// 	width: 1em;
					// 	height: 1em;
					// 	border: 0.15em solid $blue-dark;
					// 	position: absolute;
					// 	right: 1em;
					// }
				}
				.Settings-themeIntro {
					padding: 0.8em;
					padding-top: 0em;
					font-size: 1.2em;
				}
				&.selected .Settings-themeName::before {
					transform: rotate(45deg) scaleX(-1) scale(1);
				}
			}
		}

		.Settings-info {
			position: absolute;
			width: 29em;
			text-align: center;
			top: -10em;
			left: -17.5em;
			font-size: 1.2em;
		}
		.Settings-feedback {
			margin-top: 0em;
			font-size: 0.9em;
			color: red;
		}
		.Button {
			margin-right: 0.5em;
			margin-top: 1em;
			width: auto;
			padding: 0.3em 2.5em;
			font-size: 1.1em;
		}
		.Settings-gameCode {
			text-transform: uppercase;
			position: absolute;
			left: -14.8em;
			top: 3.5em;
			div {
				font-size: 2.5em;
				font-family: 'AvenirBold';
				margin-left: -0.5em;
			}
		}
	}
	.Button {
		margin-bottom: 0.5em;
		margin-right: 1em;
		padding: 0.3em 0.2em;
		background-color: $blue-dark;
		color: white;
		font-size: 1em;
		font-weight: normal;
		position: relative;
		width: 12em;
		border-radius: 0.75em;
		text-transform: none;
	}
}