$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ThemePoints {
	margin-top: 0.5em;
	width: 100%;
	height: 1em;
	background-color: $blue-light;
	border-radius: 5em;
	position: relative;
	.ThemePoints-background {
		width: 100%;
		height: 100%;
		position: absolute;
		overflow: hidden;
		left: 0;
		border-radius: 5em;
		.ThemePoints-filling {
			position: absolute;
			width: 0%;
			height: 1em;
			background-image: linear-gradient(#78BA59, #128F33);
			top: 0em;
			left: 0em;
			-webkit-transition: width 0.5s cubic-bezier(0.3, -1, 0.7, 2);
			-moz-transition: width 0.5s cubic-bezier(0.3, -1, 0.7, 2);
			-o-transition: width 0.5s cubic-bezier(0.3, -1, 0.7, 2);
			transition: width 0.5s cubic-bezier(0.3, -1, 0.7, 2);
		}
	}
	.ThemePoints-divisions {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: visible;
		top: 0.05em;
		z-index: 2;
		@include flex('space-between', 'center');
		.ThemePoints-division {
			width: 25%;
			height: 3.5em;
			overflow: visible;
			display: inline-block;
			position: relative;
			&::after {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				background-image: url('../../assets/images/icon-tail.svg');
				background-repeat: no-repeat;
				background-position: right top;
				background-size: 2.5em;
			}
			&:first-of-type {
				width: 22.5%;
			}
			&:last-of-type::before {
				border-right: none;
			}
			&--filled {
				&::after {
					background-image: url('../../assets/images/icon-tail-filled.svg');
				}
			}
			&--animate {
				&::after {
					-webkit-animation: jello-horizontal 0.9s both;
					animation: jello-horizontal 0.9s both;
				}
			}
		}
	}
}